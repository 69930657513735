import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Reveal from '../reveal'
import Img from 'gatsby-image'

class ProjectShortDescription extends Component {
  render() {
    const { title, description, text, className, images, fixedTitleWidth } = this.props
    return (
      <Reveal>
        <div className={`cs-project-all-in-one ${className}`}>
          <div className='container'>
            <div className='box-cont'>
                <div className={'box'}>
                    <div className={`${fixedTitleWidth ? 'fixWidth' : ''}`}>
                        <h2 className="maxWidthIndicator" dangerouslySetInnerHTML={{ __html: title }}></h2>
                        <p className="description">{description}</p>
                        <p className="description-smaller">{text}</p>
                    </div>
                </div>
                {images.nodes.map((img, i) => (
                    <div className="box" key={'webImages' + i}>
                    <Img
                        fluid={img.childImageSharp.fluid}
                        alt={title} />
                    </div>
                ))}
              {/*  <div className="box text-md-left" style={{ marginTop: '2rem' }}>
                  <LinkBtn>
                    <TranslatedLink to={projectDetail}>
                      Prejsť do detailu projektu
                    </TranslatedLink>
                  </LinkBtn>
              </div> */}
            </div>
            </div>
        </div>
      </Reveal>
    )
  }
}

ProjectShortDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}
ProjectShortDescription.defaultProps = {
  fixedTitleWidth: false
}

export default ProjectShortDescription
import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import AllInOne from '../../../../../components/case-studies/AllInOne'
import ProjectShortDescription from '../../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../../components/case-studies/ColorShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Layout from '../../../../../components/layout'
import Reveal from '../../../../../components/reveal'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import MobileCarousel from '../../../../../components/case-studies/MobileCarousel'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'

class Letenky extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter

    return (
      <Layout showBackButton={true}>
        <Landing
          disableMaxHeight={true}
          title={'Firemná&nbspwebstránka<br>Game days'}
          description={"Prípadová štúdia"}
          description2={'Medzinárodný herný festival sa dočkal reprezentatívneho webu na mieru vrátane nového loga a kompletného brandingu.'}
          text={'Hráčom je dobre známy populárny herný festival Game days. Každý, koho zaujíma a chce zostať v obraze ho odteraz nájde na prepracovanej štýlovej webovej stránke, ktorú sme pripravili.'}
          img={data.landingImage}
          pageMode={true}
          noShadow={true}
          what_we_done={[
            { text: 'UX & web design' },
            { text: 'logo & branding' },
            { text: 'grafický design a materiály' },
            { text: 'kódovanie front-endu (bootstrap)' },
            { text: 'programovanie (php + javascript)' }
          ]} />

        <AboutProject
          title={'Moderná webstránka na mieru nevznikne sama'}
          text={'Výroba webstránky na najvyššej úrovni si vyžaduje profesionálny prístup.'}
          list={[
            {
              title: 'Uchopenie projektu',
              text: 'Cieľom klienta bolo vytvoriť web, z ktorého bude hneď zrejmé, že ide o platformu pre hráčov. Museli sme teda navrhnúť webový dizajn, ktorý by bol jednotný a držal krok so <a class="blue-link" href="/webdizajn">svetovými trendami</a>. Najskôr sme si s klientom prešli všetky požiadavky, aby sme <b>pochopili jeho očakávania</b> a spracovali projektovú dokumentáciu, ktorá by ich reálne naplnila.',
            },
            {
              title: 'Návrh riešenia',
              text: 'Úlohou bolo vytvorenie webstránky, ktorá zaujme cieľovú skupinu, a tá rýchlo nájde všetky potrebné informácie o festivale. To všetko v modernom webdizajne s <a class="blue-link" href="/tvorba-loga">novým logom</a> a <a class="blue-link" href="/branding">brandingom</a>, a zároveň s najlepšou technologickou úrovňou <b>funkcií, rýchlosti a SEO</b>. V rámci úzkej spolupráce sme s klientom diskutovali, ako tieto plány zrealizovať.',
            },
            {
              title: 'Priebeh vývoja',
              text: 'Dizajnér mal voľnú ruku, popustil uzdu kreativite a vytvoril originálny štýl/moodboard. Ďalej sa navrhli varianty loga a celá korporátna identita. Po schválení sme sa pustili do wireframov (rozloženia stránok) a finálneho webdizajnu. Pravidelne sme klientovi prezentovali výstupy <b>a diskutovali o nich</b>. Projekt sme finálne zverili do rúk našich <a class="blue-link" href="/tvorba-webov">programátorov</a>.',
            },
            {
              title: 'Úspešný výsledok',
              text: 'Pred samotným spustením web optimalizujeme pre prehliadače, responzívnosť a SEO, pre čo najlepšie pozície na Googli. Odovzdaním webu spolupráca s klientom nekončí a projekt vylepšujeme ďalej. Sledujeme správanie návštevníkov cez Hotjar a Google Analytics a na základe získaných dát klientom radíme <b>ako zvýšiť ich tržby.</b>',
            }
          ]}
        />

        <ProjectShortDescription
          fixedTitleWidth={true}
          title={'Tvorba brandu'}
          description={'Predpokladom plnohodnotnej firemnej identity je pochopenie projektu a jeho zámeru. Návrh loga prichádza potom.'}
          text={'Po konzultáciách s klientom sme pripravili zrozumiteľné firemné princípy, ktoré by mal projekt prezentovať. Následne sme odkomunikovali základné piliere toho, ako sa <b>odlíšiť od konkurencie</b> a cez nový branding značky ukázať jedinečnú esenciu firmy.'} />

        <Reveal>
          <div className={'container p-0 mb-4'}>
            <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#000000', nameColor: '#FFFFFF', name: 'Čierna' },
          { code: '#9B48D5', nameColor: '#FFFFFF', name: 'Fialová' },
          { code: '#ffffff', nameColor: '#6B7B8A', name: 'Biela' },
        ]} />

        <AllInOne
          className="purple"
          title={'Webový dizajn na mieru'}
          description={'Profesionálna webstránka sa bez neho nezaobíde.'}
          text={'Prepracované UX, high-fidelity wireframy a webový dizajn na mieru spĺňajú všetko, čo by mal moderný web obsahovať. To všetko zvyšuje dôveryhodnosť a profesionalitu konferencie akou je Game days. Perfektná responzívnosť a mobile first prístup sú už len čerešničkou na torte.'}
          projectDetail={'/nase-prace/webstranky/firemne/gamedays'}//TODO
          images={data.webImages}
        />

        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="mobilnetelefony" />
          </div>
        </Reveal>


        <MobileCarousel
          description='Väčšina návštevníkov webstránky prichádza cez mobily. Myslíme na to.'
          items={data.mobileGallery.nodes}
          smallDescription="Vytvoriť responzívny web do posledného detailu je kľúčové, pretože určuje, či bude <a class='red-link' href='/ux-ui-dizajn'>UX (user experience)</a> pre návštevníkov jednoducho použiteľné. Pri projektoch vždy dbáme, aby boli prehľadné a intuitívne na smartfónoch (mobile first), tabletoch a ďalších rozlíšeniach. <br/><br/>Aj vďaka tomu sa zvyšuje šanca, že sa z návštevníkov stanú zákazníci, využijú služby, zakúpia produkt či vykonajú inú požadovanú akciu bez akýchkoľvek prekážok. Toto by mala byť priorita každého, kto má vlastný web a chce, aby mu prinášal čo najväčší zisk."
        />

        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.gamedays.sk" target='_blank' className='link-secondary'>www.gamedays.sk</a>
        </div>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Hypoteka'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/firemne/hypoteka/'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/gamedays/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
  landingImage: file(relativePath: { eq: "case-studies/game-days/visual.png" }) {
      childImageSharp {
          fluid( maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp
          }
      }
  }
  clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
      childImageSharp {
          fixed( width: 250) {
              ...GatsbyImageSharpFixed_withWebp
          }
      }
  }
  webImages: allFile(filter: { relativePath: {regex: "/(case-studies/game-days/webGallery/)/"} }, sort: {fields:name}) {
      nodes {
          childImageSharp {
              fluid( maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
              }
          }
      }
  }
  mobileGallery: allFile(filter: { relativePath: {regex: "/(case-studies/game-days/mobileGallery/)/"} }, sort: {fields:name}) {
        nodes {
            childImageSharp {
                fluid( maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
  logos: file(relativePath: { eq: "case-studies/game-days/logos.png" }) {
      childImageSharp {
          fluid( maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
          }
      }
  }
}
`

export default Letenky
